import React, { FC } from 'react';
import { HttpError, HttpStatusCode } from '@core/http';

import * as Styled from './ErrorPage.styles';
import { Link } from 'react-router-dom';
import { useIdParam } from '@core/router';
import { SharedButton } from '@styles/shared';
import { Door } from '@modules/doors/model';

interface ErrorPageProps {
  error?: HttpError;
}

const ErrorPage: FC<ErrorPageProps> = ({ error = HttpError.notFound }) => {
  const number = useIdParam<Door.Number>('number');

  return (
    <Styled.ErrorPageContainer aria-live="polite">
      <div>
        <h1>
          {HttpStatusCode.NOT_FOUND === error.status
            ? 'La page que vous cherchez est introuvable'
            : `Une erreur ${error.status} est survenue`}
        </h1>
      </div>

      {number ? (
        <>
          <p>Nous vous invitons à revenir à l'accueil</p>

          <SharedButton as={Link} to={`/${number}`} btnType="secondary">
            Revenir à l'accueil
          </SharedButton>
        </>
      ) : null}
    </Styled.ErrorPageContainer>
  );
};

export default ErrorPage;
