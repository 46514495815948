import React, { FC } from 'react';
import { Typography } from '@styles/shared';
import successAnimation from '@assets/animations/check.json';
import * as Styled from './CodeSuccess.styles';
import AnimatedIcon from '@shared/components/animated-icon/AnimatedIcon';
import Seo from '@shared/modules/seo/Seo';
import logo from '@assets/logos/logo.svg';

const CodeSuccess: FC = () => {
  return (
    <Styled.CodeSuccessContainer aria-live="assertive">
      <Seo title="Porte ouverte" />

      <Styled.CodeSuccessContent>
        <AnimatedIcon animationData={successAnimation} style={{ width: 140 }} settings={{ loop: false }} />
        <Typography $level="h1" as="h1">
          Porte ouverte !
        </Typography>
      </Styled.CodeSuccessContent>

      <Styled.CodeSuccessFooter>
        <img alt="Logo Hapiix" src={logo} />

        <Typography $level="standard">A bientôt !</Typography>

        <Typography $level="small">Vous pouvez fermer l'onglet</Typography>
      </Styled.CodeSuccessFooter>
    </Styled.CodeSuccessContainer>
  );
};

export default CodeSuccess;
