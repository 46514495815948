import React, { FC } from 'react';

import * as Styled from './DoorNotFound.styles';

const DoorNotFound: FC = () => {
  return (
    <Styled.DoorNotFoundContainer>
      <h1>Porte non trouvée dans le système</h1>
    </Styled.DoorNotFoundContainer>
  );
};

export default DoorNotFound;
