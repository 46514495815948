import React, { FC } from 'react';

import * as Styled from './LegalLinks.styles';
import config from '@root/config';

const LegalLinks: FC = () => (
  <Styled.LegalLinksContainer>
    <a href={config.VITE_LEGAL_NOTICE_URL} target="_blank" rel="noopener noreferrer">
      Mentions légales
    </a>
    <a href={config.VITE_CGU_URL} target="_blank" rel="noopener noreferrer">
      CGU
    </a>
  </Styled.LegalLinksContainer>
);

export default LegalLinks;
