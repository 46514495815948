import React, { FC, useMemo } from 'react';
import { useRange, VirtualizedList } from '@shared/modules/range';
import HousingOccupantRow from './row/HousingOccupantRow';
import { useIdParam } from '@core/router';
import { defaultSearchFilter, searchFilterParser } from '@shared/modules/filter';
import { Filters } from '@shared/modules/filter/components';
import { useNavigate } from 'react-router-dom';
import { useDoorContext } from '@modules/doors/context';
import Seo from '@shared/modules/seo/Seo';

import * as HousingOccupantService from './service';
import { Door } from '@modules/doors/model';
import { HousingOccupant } from '@modules/housing-occupants/model';
import LegalLinks from '@modules/housing-occupants/legal/LegalLinks';

import * as Styled from './ListPage.styles';
import { Typography } from '@styles/shared';
import info from '@assets/icons/info-bubble.svg';

const HousingOccupantListPage: FC = () => {
  const { door, setCallingHousingOccupant } = useDoorContext();

  const number = useIdParam<Door.Number>('number');

  const navigate = useNavigate();

  const rangeTask = useMemo(() => HousingOccupantService.getHousingOccupants(number), [number]);

  const { range, filter, handleLoadMore, handleFilter } = useRange(rangeTask, defaultSearchFilter);

  const onSelectOccupant = (occupant: HousingOccupant) => {
    if (occupant.isAvailable) {
      setCallingHousingOccupant(occupant);
      navigate('call');
    }
  };

  return (
    <>
      <Seo title={door.customerName ?? undefined} />

      <Styled.ListContainer>
        <Filters
          filter={filter}
          defaultFilter={defaultSearchFilter}
          parser={searchFilterParser}
          search
          onFilter={handleFilter}
        />

        <Styled.ListContent>
          <VirtualizedList range={range} loadMore={handleLoadMore}>
            {occupant => <HousingOccupantRow occupant={occupant} onSelect={onSelectOccupant} />}
          </VirtualizedList>
        </Styled.ListContent>

        <Styled.ListInfo
          href="https://www.hapiix.com/contact/creation-de-compte"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={info} alt="Info" height={40} width={40} />

          <Styled.ListInfoText>
            <Typography $level="small">Vous n'apparaissez pas dans l'annuaire ?</Typography>
            <Typography $level="small">Cliquez ici pour finaliser votre inscription.</Typography>
          </Styled.ListInfoText>
        </Styled.ListInfo>

        <LegalLinks />
      </Styled.ListContainer>
    </>
  );
};

export default HousingOccupantListPage;
