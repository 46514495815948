import { Color, DefaultTheme } from 'styled-components';

const backgroundColors: Color = {
  100: '#ffffff',
  200: '#f8f7f5',
  300: '#f4f4f4',
  400: '#f8f3e8',
  500: '#d0d0d0',
  800: '#404040',
  900: '#000000',
};

const theme: DefaultTheme = {
  colors: {
    primary: '#167e59',
    secondary: '#167e59',
    background: backgroundColors,
    success: '#21cc87',
    error: '#cc0000',
    headerBackground: '#ffffff',
  },
  font: {
    sizes: {
      number: '38px',
      h1: '30px',
      large: '25px',
      smallLarge: '25px',
      standard: '16px',
      medium: '13px',
      small: '12px',
      name: '13px',
      navigation: '16px',
    },
  },
  breakpoints: {
    large: 1280,
    desktop: 1080,
    tablet: 950,
    mobile: 600,
  },
};

export default theme;
