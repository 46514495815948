import styled from 'styled-components';

import { backgroundColor, readableColor, secondaryColor } from '@styles/utils';
import clearIcon from '@assets/icons/list/clear.svg';

export const CodePageContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const CodeContainer = styled.div`
  flex: 1 1 auto;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(4, 1fr);
  row-gap: 10px;
  column-gap: 10px;
  align-items: center;
  text-align: center;
  padding: 0 10px 10px;
  width: 100%;

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;

    > button {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 30px;
      font-weight: 500;
      border-radius: 50%;
      border: none;
      outline: none;
      color: ${backgroundColor('900')};
      background-color: ${backgroundColor('100')};
      box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
      height: 18vw;
      width: 18vw;
      cursor: pointer;

      &:hover {
        color: ${backgroundColor('100')};
        background-color: ${readableColor(secondaryColor)};
      }
    }
  }
`;

export const CodeInputContainer = styled.div`
  position: relative;
  flex: 0 0 auto;
  display: flex;
  justify-content: center;

  input {
    color: ${backgroundColor('800')} !important;
    width: 90%;
    background-color: transparent;
    font: inherit;
    font-size: 25px;
    font-weight: bold;
    text-align: center;
    padding: 0 6px;
    border: none;
    border-radius: 16px;
    margin: 10px 20px;
    line-height: 1;
    height: 42px;
    outline: none;

    &::placeholder {
      position: relative;
      top: -3px;
      font-size: 16px;
      font-weight: normal;
      opacity: 1;
    }

    &:focus,
    &::selection {
      background-color: ${backgroundColor('100')} !important;
      outline: 1px solid ${backgroundColor('800')} !important;
    }

    /* Chrome, Safari, Edge, Opera */
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    &[type='number'] {
      -moz-appearance: textfield;
    }

    &:not(:placeholder-shown) {
      background: ${backgroundColor('100')};
    }
  }
`;

export const CodeInputClearButton = styled.button`
  position: absolute;
  top: 10px;
  right: 20px;
  height: 40px;
  width: 40px;
  background: url(${clearIcon}) no-repeat center center;
  border: none;
  outline: none;
  cursor: pointer;
`;
