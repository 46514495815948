import styled from 'styled-components';
import { backgroundColor } from '@styles/utils';

export const ImageStyle = styled.img`
  &.lazyload:not([src]) {
    visibility: hidden;
  }

  &.lazyload,
  &.lazyloading {
    background: ${backgroundColor('200')};
  }
`;
