import styled from 'styled-components';

export const LayoutContainer = styled.div`
  flex: 1 1 auto;
  position: relative;
  display: flex;
`;

export const LayoutContent = styled.div<{ $footer: boolean }>`
  position: relative;
  flex: 1 1 auto;
  padding-bottom: ${props => (props.$footer ? '77px' : 0)};
`;
