import React, { FC, useMemo } from 'react';
import { renderNullable } from '@shared/utils/render';
import { Typography } from '@styles/shared';
import * as Styled from './Header.styles';
import { Link } from 'react-router-dom';
import { Door } from '@modules/doors/model';
import Image from '@shared/modules/bucket/components/Image';
import { pipe } from 'fp-ts/function';
import * as O from 'fp-ts/Option';
import { BucketResource } from '@shared/modules/bucket/model';

interface HeaderProps {
  door: Door;
}

const Header: FC<HeaderProps> = ({ door }) => {
  const [logo, showCustomerName] = useMemo<[BucketResource | null, boolean]>(() => {
    const getLogoFromCharter = (charter: Door.Charter): O.Option<[BucketResource, boolean]> =>
      pipe(
        O.fromNullable(charter.logo),
        O.map(logo => [logo, !!charter.displayNameAndLogo]),
      );

    return pipe(
      getLogoFromCharter(door.housingSiteCharter),
      O.alt(() => getLogoFromCharter(door.customerCharter)),
      O.getOrElse<[BucketResource | null, boolean]>(() => [null, true]),
    );
  }, [door.customerCharter, door.housingSiteCharter]);

  return (
    <Styled.HeaderContainer id="header" as={Link} to=".">
      {renderNullable(logo, logo => (
        <Styled.HeaderImage>
          <Image width={230} height={66} resource={logo} imgixParams={{ fit: 'fill', fill: 'solid', fm: 'png' }} />
        </Styled.HeaderImage>
      ))}

      <Styled.HeaderInfos>
        {showCustomerName ? <Typography $level="standard">{door.customerName}</Typography> : null}

        <Typography $level="standard">{door.housingSiteName}</Typography>

        {renderNullable(door.buildingName, buildingName => (
          <Styled.HeaderInfosBuilding>{buildingName}</Styled.HeaderInfosBuilding>
        ))}
      </Styled.HeaderInfos>
    </Styled.HeaderContainer>
  );
};

export default Header;
