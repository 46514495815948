import React, { FC, useState } from 'react';
import { BucketResource } from '@shared/modules/bucket/model';
import generateSizes, { SizesParams } from 'html-sizes';
import { SrcSetOptions } from '@imgix/js-core';

import * as Styled from './Image.styles';
import { buildImgixSrcAndSrcSets } from '@shared/modules/bucket/utils';

const DEFAULT_IMGIX_PARAMS = {
  auto: 'compress,format',
  q: 60,
};

interface ImageProps
  extends Omit<React.ImgHTMLAttributes<HTMLImageElement>, 'src' | 'sizes' | 'placeholder' | 'resource'> {
  tag?: 'img' | 'source';
  resource: Pick<BucketResource, 'url'>;
  sizes?: SizesParams;
  imgixParams?: Record<string, any>;
  lazy?: boolean;
  srcSetOptions?: SrcSetOptions;
  className?: string;
}

const Image: FC<ImageProps> = ({
  tag = 'img',
  resource,
  sizes: receivedSizes,
  width,
  height,
  imgixParams,
  lazy,
  srcSetOptions: receivedSrcSetOptions = {},
  className: receivedClassName,
  ...props
}) => {
  const [sizes] = useState(() => (receivedSizes ? generateSizes(receivedSizes, { maxDPR: 2 }) : undefined));

  const srcSetOptions: SrcSetOptions = {
    ...receivedSrcSetOptions,
    minWidth: receivedSrcSetOptions.minWidth ?? 300,
    maxWidth: receivedSrcSetOptions.maxWidth ?? 2000,
    devicePixelRatios: [1, 2],
  };

  const [src, srcSet] = buildImgixSrcAndSrcSets(
    resource.url,
    { ...DEFAULT_IMGIX_PARAMS, ...imgixParams, w: width, h: height },
    srcSetOptions,
  );

  const className = lazy ? `${receivedClassName ? `${receivedClassName} lazyload` : 'lazyload'}` : receivedClassName;

  const attributes = lazy
    ? {
        'data-sizes': sizes,
        'data-src': src,
        'data-srcset': srcSet,
      }
    : {
        sizes: sizes,
        src: src,
        srcSet: srcSet,
      };

  return <Styled.ImageStyle as={tag} width={width} height={height} {...attributes} className={className} {...props} />;
};

export default Image;
