import { useEffect } from 'react';

export function useReal100vh() {
  useEffect(() => {
    const updateViewHeight = () => {
      const root = document.getElementById('root');

      if (root) {
        root.style.minHeight = `${window.innerHeight}px`;
      }
    };

    updateViewHeight();

    window.addEventListener('resize', updateViewHeight);

    return () => {
      window.removeEventListener('resize', updateViewHeight);
    };
  }, []);
}
