import React, { FC, useEffect, useRef } from 'react';

import lottie, { AnimationConfig } from 'lottie-web';

export interface AnimatedIconProps {
  animationData: any;
  settings?: Partial<AnimationConfig>;
  style?: React.CSSProperties;
  className?: string;
}

const AnimatedIcon: FC<AnimatedIconProps> = ({ animationData, settings, style, className }) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref.current) {
      const anim = lottie.loadAnimation({
        ...settings,
        container: ref.current,
        renderer: 'svg',
        animationData,
      });

      return () => anim.destroy();
    }
  }, [ref, settings, animationData]);

  return <div ref={ref} className={className} style={style} aria-hidden="true" />;
};

export default AnimatedIcon;
