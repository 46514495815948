import { backgroundColor } from '@styles/utils';
import styled, { css } from 'styled-components';

export const AvatarContainer = styled.div<{ $size: number; $filled: boolean }>`
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${backgroundColor('400')};
  border-radius: 50%;
  width: ${props => `${props.$size}px`};
  height: ${props => `${props.$size}px`};
  overflow: hidden;

  > span {
    color: #2a2a2a;
    font-size: ${props => `${props.$size * 0.4}px`};
    font-weight: 500;
  }

  ${props =>
    props.$filled &&
    css`
      > img {
        width: 100%;
        height: 100%;
      }
    `}
`;
