import React, { FC, useMemo } from 'react';
import errorAnimation from '@assets/animations/error.json';
import AnimatedIcon from '@shared/components/animated-icon/AnimatedIcon';
import * as Styled from './CodeError.styles';
import { Typography } from '@styles/shared';
import { Link, useLocation } from 'react-router-dom';
import Seo from '@shared/modules/seo/Seo';

import logo from '@assets/logos/logo.svg';
import { parseQueries } from '@shared/utils/queries';
import { getStringQuery } from '@shared/modules/filter';
import { renderNullable } from '@shared/utils/render';

const CodeError: FC = () => {
  const location = useLocation();

  const type = useMemo(() => getStringQuery(parseQueries(location.search), 'type'), [location.search]);

  const [title, subtitle] = useMemo<[string, string | null]>(() => {
    switch (type) {
      case 'technical':
        return ['Erreur technique', null];
      case 'forbidden':
        return ['Accès refusé', 'Vous ne pouvez pas accéder à cette porte en dehors des horaires autorisés'];
      default:
        return ['Code incorrect', null];
    }
  }, [type]);

  return (
    <Styled.CodeErrorContainer aria-live="assertive">
      <Seo title="Erreur code porte" />

      <Styled.CodeErrorContent>
        <AnimatedIcon animationData={errorAnimation} style={{ width: 160 }} settings={{ loop: false }} />

        <Typography $level="h1" as="h1">
          {title}
        </Typography>

        {renderNullable(subtitle, subtitle => (
          <Typography $level="standard" as="p" style={{ lineHeight: 1.3 }}>
            {subtitle}
          </Typography>
        ))}

        {type !== 'forbidden' ? (
          <Styled.CodeErrorButton as={Link} to="../code" replace>
            Réessayer
          </Styled.CodeErrorButton>
        ) : null}
      </Styled.CodeErrorContent>

      <Styled.CodeErrorFooter>
        <img alt="Logo Hapiix" src={logo} />

        <Typography $level="standard">A bientôt !</Typography>

        <Typography $level="small">Vous pouvez fermer l'onglet</Typography>
      </Styled.CodeErrorFooter>
    </Styled.CodeErrorContainer>
  );
};

export default CodeError;
