import React, { createContext, Dispatch, FC, SetStateAction, useContext, useState } from 'react';
import { useIdParam } from '@core/router';
import { useFetchTask } from '@core/http/hooks';
import * as DoorsService from './service';
import { renderHttpRemoteData } from '@shared/utils/render';
import { Outlet } from 'react-router-dom';
import { Colors, DefaultTheme, ThemeProvider } from 'styled-components';
import { constVoid } from 'fp-ts/function';
import { Door } from '@modules/doors/model';
import { HttpStatusCode } from '@core/http';
import ErrorPage from '@shared/components/error-page/ErrorPage';
import DoorNotFound from '@modules/doors/components/not-found/DoorNotFound';
import { HousingOccupant } from '@modules/housing-occupants/model';

function themeModifier(door: Door): (theme: DefaultTheme) => DefaultTheme {
  const extractColorsFormCharters = (
    siteCharter: Door.Charter,
    customerCharter: Door.Charter,
    colors: Colors,
  ): Partial<Colors> => ({
    primary: siteCharter.primaryColor ?? customerCharter.primaryColor ?? colors.primary,
    secondary: siteCharter.secondaryColor ?? customerCharter.secondaryColor ?? colors.secondary,
    headerBackground:
      siteCharter.headerBackgroundColor ?? customerCharter.headerBackgroundColor ?? colors.headerBackground,
  });

  return theme => ({
    ...theme,
    colors: {
      ...theme.colors,
      ...extractColorsFormCharters(door.housingSiteCharter, door.customerCharter, theme.colors),
    },
  });
}

interface DoorContextValue {
  door: Door;
  callingHousingOccupant: HousingOccupant | null;
  setCallingHousingOccupant: Dispatch<SetStateAction<HousingOccupant | null>>;
}

const DoorContext = createContext<DoorContextValue>({
  door: null as unknown as Door,
  callingHousingOccupant: null,
  setCallingHousingOccupant: constVoid,
});

const DoorContextProvider: FC = () => {
  const number = useIdParam<Door.Number>('number');

  const [door] = useFetchTask(DoorsService.getDoor, number);

  const [callingHousingOccupant, setCallingHousingOccupant] = useState<HousingOccupant | null>(null);

  return renderHttpRemoteData(
    door,
    door => (
      <DoorContext.Provider value={{ door, callingHousingOccupant, setCallingHousingOccupant }}>
        <ThemeProvider theme={themeModifier(door)}>
          <Outlet />
        </ThemeProvider>
      </DoorContext.Provider>
    ),
    undefined,
    error => (error.status === HttpStatusCode.NOT_FOUND ? <DoorNotFound /> : <ErrorPage error={error} />),
  );
};

export function useDoorContext() {
  return useContext(DoorContext);
}

export default DoorContextProvider;
