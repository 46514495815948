import React, { FC } from 'react';
import * as Styled from './Avatar.styles';
import user from '@assets/icons/user/user.svg';
import { renderNullable, renderOptional } from '@shared/utils/render';
import { HousingOccupant } from '@modules/housing-occupants/model';
import Image from '@shared/modules/bucket/components/Image';
import { pipe } from 'fp-ts/function';
import * as O from 'fp-ts/Option';
import * as A from 'fp-ts/Array';
import * as NEA from 'fp-ts/NonEmptyArray';

interface AvatarProps {
  occupant: HousingOccupant;
  size?: number;
  lazy?: boolean;
  className?: string;
}

const Avatar: FC<AvatarProps> = ({ occupant, size = 45, lazy, className }) => {
  const getInitials = () =>
    pipe(
      [O.fromNullable(occupant.lastName), O.fromNullable(occupant.firstName)],
      A.compact,
      NEA.fromArray,
      O.map(NEA.reduce('', (acc, curr) => `${acc}${curr.charAt(0)}`)),
    );

  return (
    <Styled.AvatarContainer $size={size} $filled={!!occupant.profilePicture} className={className} aria-hidden="true">
      {renderNullable(
        occupant.profilePicture,
        picture => (
          <Image
            aria-hidden
            resource={picture}
            width={size}
            height={size}
            lazy={lazy}
            imgixParams={{ fit: 'crop', crop: 'faces', mask: 'ellipse' }}
          />
        ),
        () =>
          renderOptional(
            getInitials(),
            initials => <span>{initials}</span>,
            () => <img src={user} alt="résident" width={size} />,
          ),
      )}
    </Styled.AvatarContainer>
  );
};

export default Avatar;
