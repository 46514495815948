import React, { ChangeEventHandler, FC, useEffect, useState } from 'react';
import { filterEmptyStringToNullable } from '@shared/utils/string';
import { useDebouncedCallback } from 'use-debounce';
import * as Styled from './SearchFilter.styles';

interface SearchFilterProps {
  defaultValue: string | null;
  onChange: (value: string | null) => void;
  className?: string;
}

const SearchFilter: FC<SearchFilterProps> = ({ defaultValue, onChange, className }) => {
  const [value, setValue] = useState<string>(() => defaultValue ?? '');

  useEffect(() => {
    setValue(defaultValue ?? '');
  }, [defaultValue]);

  const debouncedOnChange = useDebouncedCallback((search: string | null) => onChange(search), 400);

  const handleChange: ChangeEventHandler<HTMLInputElement> = e => {
    const { value } = e.target;

    setValue(value);
    debouncedOnChange(filterEmptyStringToNullable(value));
  };

  const clearField = () => {
    setValue('');
    onChange('');
  };

  return (
    <Styled.FilterSearchContainer>
      <Styled.FilterSearchInput
        value={value}
        onChange={handleChange}
        type="text"
        id="search"
        placeholder="Rechercher"
        autoComplete="off"
      />
      <Styled.FilterSearchClearButton aria-label="Effacer le champs de recherche" onClick={clearField} />
    </Styled.FilterSearchContainer>
  );
};

export default SearchFilter;
