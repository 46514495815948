import { Typography } from '@styles/shared';
import { backgroundColor } from '@styles/utils';
import styled from 'styled-components';

export const HousingOccupantRowContainer = styled.div<{ $isAvailable?: boolean }>`
  display: grid;
  grid-template-columns: 45px 1fr auto;
  grid-column-gap: 9px;
  align-items: center;
  padding: 7px 18px 7px 7px;
  margin: 0 20px;
  height: 60px;
  background-color: ${backgroundColor('100')};
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.07);
  border-radius: 100px;
  opacity: ${props => (props.$isAvailable ? 1 : 0.6)};
  cursor: ${props => (props.$isAvailable ? 'pointer' : 'not-allowed')};
`;

export const HousingOccupantRowNameContent = styled.div`
  overflow: hidden;
  min-width: 0;

  div,
  span {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    min-width: 0;
    max-width: 100%;
  }
`;

export const HousingOccupantRowCompanyName = styled(Typography)`
  opacity: 0.6;
`;
