import { Door } from '@modules/doors/model';
import { RangeCursor } from '@shared/modules/range';
import { SearchFilter } from '@shared/modules/filter';
import { HttpRange, httpService } from '@core/http';
import { HousingOccupant } from '@modules/housing-occupants/model';

export function getHousingOccupants(
  number: Door.Number,
): (cursor: RangeCursor, filter?: SearchFilter) => HttpRange<HousingOccupant, SearchFilter> {
  return (cursor, filter) => httpService.getRange(`/doors/${number}/housing-occupants`, cursor, filter);
}
