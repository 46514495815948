import React, { FC, ReactNode, useRef, useState } from 'react';

import * as Styled from './CodePage.styles';
import Seo from '@shared/modules/seo/Seo';
import { AudioName, useAudioContext } from '@shared/modules/audio';
import { useDoorContext } from '@modules/doors/context';
import { pipe } from 'fp-ts/function';
import * as CodeService from '../service';
import { useIdParam } from '@core/router';
import { Door } from '@modules/doors/model';
import * as TE from 'fp-ts/TaskEither';
import * as A from 'fp-ts/Array';
import { useNavigate } from 'react-router-dom';
import { HttpStatusCode } from '@core/http';
import { logSentryHttpError } from '@shared/modules/sentry/utils';

interface Key {
  char: string;
  ariaLabel?: string;
  escapedLabel?: ReactNode;
}

const keys: Array<Key> = [
  { char: '1' },
  { char: '2' },
  { char: '3' },
  { char: '4' },
  { char: '5' },
  { char: '6' },
  { char: '7' },
  { char: '8' },
  { char: '9' },
  { char: '*', ariaLabel: 'étoile', escapedLabel: <>&lowast;</> },
  { char: '0' },
  { char: '#', ariaLabel: 'dièse' },
];

function filterCode(code: Array<string>) {
  return pipe(
    code,
    A.filter(char =>
      pipe(
        keys,
        A.some(key => key.char === char),
      ),
    ),
  );
}

const CodePage: FC = () => {
  const navigate = useNavigate();

  const number = useIdParam<Door.Number>('number');

  const { door } = useDoorContext();

  const audio = useAudioContext();

  const abortController = useRef<AbortController | null>(null);

  const maxCodeLength = Math.max(...door.accessCodesCombinations);

  const [code, setCode] = useState('');

  const checkCode = (code: string) => {
    const codeLength = code.length;

    abortController.current?.abort();

    abortController.current = new AbortController();

    if (door.accessCodesCombinations.includes(codeLength)) {
      pipe(
        CodeService.openDoor(number, code, abortController.current.signal),
        TE.chainFirstIOK(() => () => {
          audio.play(AudioName.DoorOpen);

          navigate('success');
        }),
        TE.orElseFirstIOK(err => () => {
          if (codeLength >= maxCodeLength && err.status !== HttpStatusCode.ABORTED) {
            if (err.status === HttpStatusCode.UNAUTHORIZED) {
              audio.play(AudioName.IncorrectCode);

              navigate('error');
            } else if (err.status === HttpStatusCode.FORBIDDEN) {
              navigate('error?type=forbidden');
            } else {
              logSentryHttpError('[code] technical error on open door', err);

              navigate('error?type=technical');
            }
          }
        }),
      )();
    }
  };

  const handleChangeInputCode = (e: React.FormEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value.trim();

    if (value.length <= maxCodeLength) {
      const code = filterCode(value.split('')).join('');

      setCode(code);
      checkCode(code);
    }
  };

  const handleCodeButtonClick = (value: string) => () => {
    setCode(old => {
      if (old.length < maxCodeLength) {
        const newCode = pipe(old.split(''), A.append(value), filterCode).join('');

        checkCode(newCode);

        return newCode;
      }

      return old;
    });
  };

  const clearCode = () => setCode('');

  return (
    <Styled.CodePageContainer>
      <Seo title="Code porte" />

      <Styled.CodeInputContainer>
        <input
          type="tel"
          value={code}
          onChange={handleChangeInputCode}
          maxLength={maxCodeLength}
          placeholder="Composez votre code d'accès"
        />

        {code ? <Styled.CodeInputClearButton aria-label="Effacer le code d'accès" onClick={clearCode} /> : null}
      </Styled.CodeInputContainer>
      <Styled.CodeContainer>
        {keys.map(key => (
          <div key={key.char}>
            <button onClick={handleCodeButtonClick(key.char)} aria-label={key.ariaLabel}>
              {key.escapedLabel ?? key.char}
            </button>
          </div>
        ))}
      </Styled.CodeContainer>
    </Styled.CodePageContainer>
  );
};

export default CodePage;
