import React, { createContext, FC, PropsWithChildren, useContext, useMemo } from 'react';
import { AudioProgram } from './program';

const AudioContext = createContext<AudioProgram>({} as any);

export const AudioContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const audio = useMemo(() => new AudioProgram(), []);

  return <AudioContext.Provider value={audio}>{children}</AudioContext.Provider>;
};

export function useAudioContext() {
  return useContext(AudioContext);
}
