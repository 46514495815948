import styled from 'styled-components';
import { backgroundColor } from '@styles/utils';
import Sticky from 'react-stickynode';

export const FiltersSticky = styled(Sticky)`
  > .sticky {
    z-index: 10;
  }
`;

export const FiltersContainer = styled.div`
  display: flex;
  padding: 10px 20px;
  background-color: ${backgroundColor('100')};
  width: 100%;
`;
