import { readableColor, rgba, secondaryColor } from '@styles/utils';
import styled, { css } from 'styled-components';

export const FlatNumberContainer = styled.div<{ $unavailable?: boolean }>`
  padding: 1px 8px;
  border-radius: 17px;

  background-color: ${secondaryColor};
  span {
    color: ${readableColor(secondaryColor, '#fff')};
  }

  ${props =>
    props.$unavailable &&
    css`
      background-color: ${rgba(secondaryColor, 0.26)};

      span {
        color: ${readableColor(rgba(secondaryColor, 0.26), secondaryColor)};
        font-style: italic;
      }
    `};
`;
