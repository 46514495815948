import React, { FC, PropsWithChildren, Suspense, useEffect } from 'react';
import { BrowserRouter, useLocation } from 'react-router-dom';
import RootRoutes from 'routes';
import { ThemeProvider } from 'styled-components';
import { HelmetProvider } from 'react-helmet-async';

import { GlobalStyles } from '@styles/global';
import theme from '@styles/theme';
import { AudioContextProvider } from '@shared/modules/audio';
import { useReal100vh } from '@styles/hook';
import { DebouncedLineLoader } from '@layout/loaders/line-loader/LineLoader';
import SentryProvider from '@shared/modules/sentry/components/SentryProvider';
import ApiDownIndicator from '@core/http/components/ApiDownIndicator';

import Modal from 'react-modal';

Modal.setAppElement('#root');

const ScrollRestoration: FC<PropsWithChildren> = ({ children }) => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return <>{children}</>;
};

const App: FC = () => {
  useReal100vh();

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />

      <ApiDownIndicator />

      <SentryProvider>
        <AudioContextProvider>
          <HelmetProvider>
            <BrowserRouter>
              <ScrollRestoration>
                <Suspense fallback={<DebouncedLineLoader />}>
                  <RootRoutes />
                </Suspense>
              </ScrollRestoration>
            </BrowserRouter>
          </HelmetProvider>
        </AudioContextProvider>
      </SentryProvider>
    </ThemeProvider>
  );
};

export default App;
