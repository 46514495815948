import styled from 'styled-components';
import { backgroundColor } from '@styles/utils';

export const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
`;

export const ListContent = styled.div`
  flex: 1 1 auto;
`;

export const ListInfo = styled.a`
  margin: 0 20px;
  padding: 7px 18px 7px 7px;
  display: grid;
  grid-template-columns: 40px 1fr;
  grid-column-gap: 9px;
  align-items: center;
  background-color: ${backgroundColor('100')};
  border-radius: 100px;
  border: solid 1px ${backgroundColor('900')};
  color: ${backgroundColor('900')};
  cursor: pointer;
  text-decoration: none;
  height: 60px;
`;

export const ListInfoIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 100%;
  }
`;

export const ListInfoText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  p {
    margin: 0;

    &:first-child {
      padding-bottom: 3px;
    }
  }
`;
