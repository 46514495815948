import React, { FC, useEffect } from 'react';
import { RangeCursor, VirtualizedListProps } from '@shared/modules/range';
import { renderOptional } from '@shared/utils/render';
import { useWindowVirtualizer } from '@tanstack/react-virtual';
import { useThrottledCallback } from 'use-debounce';

import * as Styled from './VirtualizedList.styles';

const ROW_HEIGHT = 67;

interface LoadingRowProps {
  index: number;
  loadRow: (index: number) => void;
}

const LoadingRow: FC<LoadingRowProps> = ({ index, loadRow }) => {
  useEffect(() => {
    loadRow(index);
  });

  return null;
};

export function VirtualizedList<T>({ range, children, loadMore }: VirtualizedListProps<T>) {
  const virtualizer = useWindowVirtualizer({
    count: range.total,
    estimateSize: () => ROW_HEIGHT,
    overscan: 15,
  });

  useEffect(() => {
    virtualizer.scrollToIndex(0);
    virtualizer.measure();
  }, [range.total, virtualizer]);

  const handleLoadRow = (index: number) => {
    if (!range.loading && !range.has(index)) {
      const page = Math.floor(index / RangeCursor.DEFAULT_SIZE);

      loadMore(RangeCursor.fromPage(page));
    }
  };

  // Throttle to wait state refresh
  const throttleLoadRow = useThrottledCallback(handleLoadRow, 100);

  return (
    <Styled.VirtualizedListContainer>
      <div style={{ position: 'relative', height: virtualizer.getTotalSize(), width: '100%' }}>
        {range.total === 0 && !range.loading ? (
          <Styled.VirtualizedListPlaceholder>Aucun élément à afficher</Styled.VirtualizedListPlaceholder>
        ) : (
          virtualizer.getVirtualItems().map(row => (
            <div
              key={row.key}
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: row.size,
                willChange: 'transform',
                transform: `translateY(${row.start}px)`,
              }}
            >
              {renderOptional(
                range.get(row.index),
                item => (
                  <>{children(item, row.index)}</>
                ),
                () => (
                  <LoadingRow index={row.index} loadRow={throttleLoadRow} />
                ),
              )}
            </div>
          ))
        )}
      </div>
    </Styled.VirtualizedListContainer>
  );
}
