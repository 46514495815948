import mp3File from '@assets/sounds/sounds.mp3';
import { AudioName } from '@shared/modules/audio/model';
import { safeVibrate } from '@shared/utils/vibrate';
import { Howl } from 'howler';

// [start, duration, loop]
const sprite: Record<AudioName, [number, number] | [number, number, boolean]> = {
  [AudioName.DoorOpen]: [1000, 1600],
  [AudioName.IncorrectCode]: [3000, 1600],
  [AudioName.MsgSent]: [5000, 2000],
  [AudioName.Ring]: [7000, 4700, true],
};

export class AudioProgram {
  private howler: Howl;

  constructor() {
    this.howler = new Howl({
      src: [mp3File],
      sprite,
    });
  }

  play(name: AudioName) {
    this.howler.stop();
    this.howler.play(name);

    if (name === AudioName.IncorrectCode) {
      safeVibrate([100, 50, 100]);
    } else {
      safeVibrate(300);
    }
  }

  stop() {
    this.howler.stop();
  }
}
