import styled, { css, keyframes } from 'styled-components';

import { backgroundColor, primaryColor } from '@styles/utils';
import { ButtonType } from './Button';

export const spinnerAnimation = keyframes`
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const SharedButtonContainer = styled.button<{ $btnType: ButtonType; $loading?: boolean; $hasIcon: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: 500;
  border-radius: 41px;
  border: none;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.2);
  padding: 17px 36px;
  height: 56px;
  outline: none;
  text-decoration: none;
  cursor: pointer;
  z-index: 1;

  ${props =>
    props.$btnType === 'primary'
      ? css`
          color: ${backgroundColor('100')};
          background-color: ${primaryColor};

          &:hover {
            color: ${backgroundColor('100')};
          }
        `
      : css`
          color: ${backgroundColor('800')};
          background-color: ${backgroundColor('100')};

          &:hover {
            color: ${backgroundColor('800')};
          }
        `};

  ${props =>
    props.$hasIcon &&
    css`
      > span {
        margin-left: 15px;
      }
    `}

  ${props =>
    props.disabled &&
    css`
      opacity: 0.6;
      cursor: not-allowed;
    `}
  
  ${props => props.$loading && css`
    position: relative;
    pointer-events: none;

    &:before {
      border-radius: 50%;
      animation: ${spinnerAnimation} 1.2s linear infinite;
      content: '';
      position: absolute;
      height: 16px;
      width: 16px;
      top: calc(50% - (21px / 2));
      left: calc(50% - (19px / 2));
      border: 2px solid #fff;
      border-top-color: transparent;
    }
  `}
`;

export const SharedButtonLinkContainer = styled.button`
  color: ${backgroundColor('800')};
  font-family: inherit;
  font-size: 17px;
  font-weight: normal;
  text-decoration: underline;
  background: none;
  border: none;
  outline: none;

  &:hover {
    color: ${backgroundColor('800')};
    text-decoration: underline;
  }
`;
