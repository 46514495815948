import React, { FC } from 'react';
import * as Styled from './Loader.styles';

const Loader: FC = () => {
  return (
    <Styled.LoaderContainer>
      <div />
      <div />
      <div />
      <div />
    </Styled.LoaderContainer>
  );
};

export default Loader;
