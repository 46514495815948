import styled from 'styled-components';
import { backgroundColor, fontSize } from '@styles/utils';

export const LegalLinksContainer = styled.div`
  padding: 20px 10px 40px;
  text-align: center;

  a {
    font-size: ${fontSize.small};
    color: ${backgroundColor('800')};
    text-decoration: underline;
    margin: 5px;
  }
`;
