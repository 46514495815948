import styled, { css, DefaultTheme, FlattenInterpolation, FontSizeKey, ThemeProps } from 'styled-components';
import { backgroundColor, fontSize } from '@styles/utils';

export interface TypographyProps {
  $level: FontSizeKey;
  $color?: string;
  $weight?: string;
  $italic?: boolean;
  $uppercase?: boolean;
  $inverted?: boolean;
  $ellipsis?: boolean;
  $maxWidth?: number;
}

export const typographyStyles: Record<FontSizeKey, FlattenInterpolation<ThemeProps<DefaultTheme>>> = {
  number: css`
    color: #123a6a;
    font-weight: 500;
  `,
  h1: css`
    color: ${backgroundColor('800')};
    font-weight: bold;
  `,
  large: css`
    color: ${backgroundColor('800')};
    font-weight: bold;
  `,
  smallLarge: css`
    color: ${backgroundColor('800')};
    font-weight: normal;
  `,
  standard: css`
    color: ${backgroundColor('800')};
    font-weight: normal;
  `,
  medium: css`
    color: ${backgroundColor('800')};
    font-weight: normal;
  `,
  small: css`
    color: ${backgroundColor('800')};
    font-weight: normal;
  `,
  name: css`
    color: ${backgroundColor('800')};
    font-weight: bold;
  `,
  navigation: css`
    color: #313131;
    font-weight: 500;
  `,
};

export const Typography = styled.p<TypographyProps>`
  ${props => typographyStyles[props.$level]};
  font-size: ${props => fontSize[props.$level]};

  ${props =>
    props.$color &&
    css`
      color ${props.$color};
    `};

  ${props =>
    props.$weight &&
    css`
      font-weight: ${props.$weight};
    `};

  ${props =>
    props.$inverted &&
    css`
      color: ${backgroundColor('100')};
    `};

  ${props =>
    props.$italic &&
    css`
      font-style: italic;
    `};

  ${props =>
    props.$uppercase &&
    css`
      text-transform: uppercase;
    `};

  ${props =>
    props.$ellipsis &&
    css`
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    `};

  ${props =>
    props.$maxWidth &&
    css`
      max-width: ${props.$maxWidth}px;
    `};
`;
