import { backgroundColor, fontSize, rgba } from '@styles/utils';
import styled from 'styled-components';
import searchIcon from '@assets/icons/list/search.svg';
import clearIcon from '@assets/icons/list/clear.svg';

export const FilterSearchContainer = styled.div`
  position: relative;
  display: inline-flex;
  border: none;
  border-radius: 10px;
  width: 100%;
`;

export const FilterSearchInput = styled.input`
  width: 100%;
  height: 40px;
  border: none;
  padding: 10px 45px 10px;
  border-radius: 10px;
  background: url(${searchIcon}) no-repeat left 10px center ${backgroundColor('300')};
  box-sizing: border-box;
  font-family: inherit;
  font-size: ${fontSize.standard};
  outline: none;

  &:focus,
  &::selection {
    outline: 1px solid ${backgroundColor('800')} !important;
  }

  &::placeholder {
    color: ${rgba(backgroundColor('800'), 0.5)};
    font-size: ${fontSize.standard};
    font-weight: normal;
  }

  &:placeholder-shown + button {
    display: none;
  }
`;

export const FilterSearchClearButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  height: 40px;
  width: 40px;
  background: url(${clearIcon}) no-repeat center center;
  border: none;
  outline: none;
  cursor: pointer;
`;
