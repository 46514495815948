import styled from 'styled-components';

export const VirtualizedListContainer = styled.div`
  padding-top: 20px;
`;

export const VirtualizedListPlaceholder = styled.div`
  padding: 0 20px;
  text-align: center;
`;
