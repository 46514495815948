import React, { PureComponent, ReactNode } from 'react';

import { Filter, FilterQueryParser, SearchFilter } from '@shared/modules/filter/model';
import { isFilterEmpty } from '@shared/modules/filter/utils';
import { parseQueries, stringifyQueries } from '@shared/utils/queries';

import { Location, NavigateFunction, useLocation, useNavigate } from 'react-router-dom';
import * as Styled from './Filters.styles';
import SearchFilterComponent from './search/SearchFilter';

interface RouteComponentProps {
  navigate: NavigateFunction;
  location: Location;
}

interface FiltersProps<F extends Filter> {
  filter: F;
  defaultFilter: F;
  parser: FilterQueryParser<F>;
  search?: F extends SearchFilter ? boolean : never;
  onFilter: (filter: F) => void;
  right?: ReactNode;
}

class Filters<F extends Filter> extends PureComponent<FiltersProps<F> & RouteComponentProps> {
  componentDidMount() {
    const currentFilter = this.parseFilter(this.props.location.search);

    if (!isFilterEmpty(this.props.defaultFilter) && isFilterEmpty(currentFilter)) {
      this.handleFilter(this.props.defaultFilter);
    } else {
      this.props.onFilter(currentFilter);
    }
  }

  componentDidUpdate(prevProps: Readonly<FiltersProps<F> & RouteComponentProps>) {
    if (this.props.location.search !== prevProps.location.search) {
      this.props.onFilter(this.parseFilter(this.props.location.search));
    }
  }

  private parseFilter = (search: string): F => this.props.parser(parseQueries(search));

  private handleFilter = (filter: F) =>
    this.props.navigate(
      {
        pathname: this.props.location.pathname,
        search: stringifyQueries(filter),
      },
      { replace: true },
    );

  private handleSearch = (search: string | null) => this.handleFilter({ ...this.props.filter, search });

  render() {
    const { filter } = this.props;

    return (
      <Styled.FiltersSticky innerActiveClass="sticky">
        <Styled.FiltersContainer>
          <SearchFilterComponent defaultValue={filter.search as any} onChange={this.handleSearch} />
        </Styled.FiltersContainer>
      </Styled.FiltersSticky>
    );
  }
}

const FiltersWrapper = <F extends Filter>(props: FiltersProps<F> & { children?: ReactNode }) => {
  const navigate = useNavigate();
  const location = useLocation();

  return <Filters {...props} navigate={navigate} location={location} />;
};

export default FiltersWrapper as <F extends Filter>(props: FiltersProps<F>) => JSX.Element;
