import { pipe } from 'fp-ts/function';
import * as O from 'fp-ts/Option';
import * as S from 'fp-ts/string';
import { getLuminance, rgba as polishedRgba } from 'polished';
import { Color, ColorKey, DefaultTheme, ThemeProps } from 'styled-components';

type ColorOption = string | ((props: ThemeProps<DefaultTheme>) => string);

function getColor(color: Color): (key: ColorKey) => string {
  return key => color[key];
}

export function primaryColor({ theme }: ThemeProps<DefaultTheme>): string {
  return theme.colors.primary;
}

export function secondaryColor({ theme }: ThemeProps<DefaultTheme>): string {
  return theme.colors.secondary;
}

export function backgroundColor(key?: ColorKey): (props: ThemeProps<DefaultTheme>) => string {
  return props =>
    pipe(
      O.fromNullable(key),
      O.getOrElse<ColorKey>(() => '100'),
      getColor(props.theme.colors.background),
    );
}

export function successColor({ theme }: ThemeProps<DefaultTheme>): string {
  return theme.colors.success;
}

export function errorColor({ theme }: ThemeProps<DefaultTheme>): string {
  return theme.colors.error;
}

export function headerBackground({ theme }: ThemeProps<DefaultTheme>): string {
  return theme.colors.headerBackground;
}

export function rgba(
  colorFn: (props: ThemeProps<DefaultTheme>) => string,
  amount: number,
): (props: ThemeProps<DefaultTheme>) => string {
  return props => polishedRgba(colorFn(props), amount);
}

function applyColorOption(color: ColorOption): (props: ThemeProps<DefaultTheme>) => string {
  return props => (S.isString(color) ? color : color(props));
}

export function readableColor(
  color: ColorOption,
  returnIfDarkColor: ColorOption = color,
  returnIfLightColor: ColorOption = 'rgb(64, 64, 64)',
  limit = 0.4,
): (props: ThemeProps<DefaultTheme>) => string {
  return props =>
    getLuminance(applyColorOption(color)(props)) > limit
      ? applyColorOption(returnIfLightColor)(props)
      : applyColorOption(returnIfDarkColor)(props);
}
