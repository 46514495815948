import styled from 'styled-components';

export const DoorNotFoundContainer = styled.div`
  display: flex;
  height: 100%;
  padding: 20px;
  flex: 1 1 auto;
  align-items: center;
  justify-content: center;
  text-align: center;
`;
