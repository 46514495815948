import { HttpError, HttpRange } from '@core/http';
import { Filter } from '@shared/modules/filter';
import debouncePromise from 'debounce-promise';
import * as EI from 'fp-ts/Either';
import * as O from 'fp-ts/Option';
import { pipe } from 'fp-ts/function';
import * as T from 'fp-ts/Task';
import { useCallback, useMemo, useState } from 'react';
import { Range, RangeCursor } from './model';
import { logSentryHttpError } from '@shared/modules/sentry/utils';

export function useRange<R, F extends Filter = {}>(
  task: (cursor: RangeCursor, filter?: F) => HttpRange<R, F>,
  defaultFilter: F,
) {
  const [range, setRange] = useState<Range<R, F>>(() => Range.default());
  const [filter, setFilter] = useState<F>(() => defaultFilter);

  const [error, setError] = useState<O.Option<HttpError>>(() => O.none);

  const fetchRange = useCallback(
    (cursor: RangeCursor, filter?: F, disableMerge?: boolean) =>
      pipe(
        task(cursor, filter),
        T.chainIOK(result =>
          pipe(
            result,
            EI.fold(
              error => () => {
                setError(O.some(error));
                setRange(old => old.setLoading(false));

                logSentryHttpError('http error on range call', error);
              },
              range => () => setRange(old => (disableMerge ? range : old.merge(range))),
            ),
          ),
        ),
      )(),
    [task],
  );

  const debouncedFetchRange = useMemo(() => debouncePromise(fetchRange, 150), [fetchRange]);

  const handleLoadMore = useCallback(
    (cursor: RangeCursor) => debouncedFetchRange(cursor, filter),
    [debouncedFetchRange, filter],
  );

  const handleRefreshIndex = useCallback(
    (index: number) => {
      const startIndex = Math.max(0, index - RangeCursor.DEFAULT_SIZE / 2);

      return fetchRange(
        {
          startIndex,
          endIndex: startIndex + RangeCursor.DEFAULT_SIZE - 1,
        },
        filter,
        true,
      );
    },
    [fetchRange, filter],
  );

  const handleFilter = useCallback(
    (filter: F) => {
      setFilter(filter);
      setRange(old => old.setLoading());

      return fetchRange(RangeCursor.initial(), filter);
    },
    [fetchRange],
  );

  return {
    range,
    filter,
    error,
    defaultFilter,
    handleLoadMore,
    handleFilter,
    handleRefreshIndex,
    setRange,
  };
}
