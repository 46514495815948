import ImgixClient, { SrcSetOptions } from '@imgix/js-core';
import queryString from 'query-string';

export function buildImgixSrcAndSrcSets(
  src: string,
  params: Record<string, any> = {},
  srcSet: SrcSetOptions | boolean = false,
): [string, string | undefined] {
  const { url, query } = queryString.parseUrl(src);

  const { hostname, protocol, pathname } = new URL(url);

  const client = new ImgixClient({
    domain: hostname,
    useHTTPS: protocol === 'https:',
    includeLibraryParam: false,
  });

  const imgixParams = { ...query, ...params };

  const generatedSrc = client.buildURL(pathname, imgixParams);

  const generatedSrcSet =
    srcSet === false ? undefined : client.buildSrcSet(pathname, imgixParams, srcSet === true ? {} : srcSet);

  return [generatedSrc, generatedSrcSet];
}

export function buildImgixSrc(src: string, params: Record<string, any> = {}) {
  const [generatedSrc] = buildImgixSrcAndSrcSets(src, params);

  return generatedSrc;
}
