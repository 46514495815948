import { DefaultTheme, FontSizeKey, ThemeProps } from 'styled-components';

import * as R from 'fp-ts/Record';
import * as String from 'fp-ts/string';
import { pipe } from 'fp-ts/function';
import theme from '../theme';

export const fontSize = pipe(
  theme.font.sizes,
  R.reduceWithIndex(String.Ord)(
    {} as Readonly<Record<Extract<FontSizeKey, string>, (props: ThemeProps<DefaultTheme>) => string>>,
    (key, acc) => ({
      ...acc,
      [key]: (props: ThemeProps<DefaultTheme>) => props.theme.font.sizes[key],
    }),
  ),
);
