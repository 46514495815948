import React, { FC } from 'react';
import { Typography } from '@styles/shared';
import * as Styled from './HousingOccupantRow.styles';
import Avatar from '@shared/modules/resident/avatar/Avatar';
import { renderNullable } from '@shared/utils/render';
import FlatNumber from '@shared/modules/resident/flat-number/FlatNumber';
import { HousingOccupant } from '@modules/housing-occupants/model';

interface HousingOccupantRowProps {
  occupant: HousingOccupant;
  onSelect: (occupant: HousingOccupant) => void;
}

const HousingOccupantRow: FC<HousingOccupantRowProps> = ({ occupant, onSelect }) => {
  const isAnonymous = !occupant.lastName && !occupant.firstName && !occupant.company;

  const handleClick = () => onSelect(occupant);

  return (
    <Styled.HousingOccupantRowContainer $isAvailable={occupant.isAvailable} role="button" onClick={handleClick}>
      <Avatar occupant={occupant} size={45} lazy />

      <Styled.HousingOccupantRowNameContent>
        {isAnonymous ? (
          <div>
            <Typography as="span" $level="name">
              {occupant.number}
            </Typography>
          </div>
        ) : occupant.lastName || occupant.firstName ? (
          <div>
            <Typography as="span" $level="medium">
              {renderNullable(occupant.lastName, lastName => (
                <Typography as="span" $level="name">
                  {lastName}
                </Typography>
              ))}{' '}
              {occupant.firstName}
            </Typography>
          </div>
        ) : null}

        {renderNullable(occupant.company, company => (
          <Styled.HousingOccupantRowCompanyName as="span" $level="medium" aria-label={`Entreprise ${occupant.company}`}>
            {company}
          </Styled.HousingOccupantRowCompanyName>
        ))}
      </Styled.HousingOccupantRowNameContent>

      <FlatNumber occupant={occupant} />
    </Styled.HousingOccupantRowContainer>
  );
};

export default HousingOccupantRow;
