import React, { FC, useLayoutEffect, useState } from 'react';

import Modal, { Styles } from 'react-modal';
import { Typography } from '@styles/shared';
import Loader from '@shared/components/loader/Loader';

const API_DOWN_EVENT_NAME = 'api-down';
const API_UP_EVENT_NAME = 'api-up';

const modalStyle: Styles = {
  overlay: {
    zIndex: 100000,
    padding: 20,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  content: {
    inset: 'unset',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  },
};

const ApiDownIndicator: FC = () => {
  const [opened, setOpened] = useState<boolean>(false);

  useLayoutEffect(() => {
    const showIndicator = () => setOpened(true);
    const hideIndicator = () => setOpened(false);

    window.addEventListener(API_DOWN_EVENT_NAME, showIndicator);
    window.addEventListener(API_UP_EVENT_NAME, hideIndicator);

    return () => {
      window.removeEventListener(API_DOWN_EVENT_NAME, showIndicator);
      window.removeEventListener(API_UP_EVENT_NAME, hideIndicator);
    };
  }, []);

  return (
    <Modal isOpen={opened} style={modalStyle}>
      <Typography $level="large">Connexion avec le serveur interrompue</Typography>

      <div style={{ padding: 20 }}>
        <Loader />
      </div>

      <Typography $level="standard">Veuillez patienter nous essayons de rétablir la connexion.</Typography>
    </Modal>
  );
};

export function showApiDownIndicator() {
  window.dispatchEvent(new Event(API_DOWN_EVENT_NAME));
}

export function hideApiDownIndicator() {
  window.dispatchEvent(new Event(API_UP_EVENT_NAME));
}

export default ApiDownIndicator;
