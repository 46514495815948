import React, { FC, useEffect } from 'react';
import { DebouncedLineLoader } from '@layout/loaders/line-loader/LineLoader';

import config from '@root/config';

// Code pragmatique identique à celui de Signal https://signal.org/assets/install-dc1e1df7eaedef75bdc6dc5ba7bd751fc37bcd8c3d844072224808ad75beda49.js
const AppStoresRedirect: FC = () => {
  useEffect(() => {
    const getStoreUrl = () => {
      if (navigator.userAgent.match('Mac OS')) {
        return config.VITE_RESIDENT_APPLE_STORE_URL;
      } else {
        return config.VITE_RESIDENT_ANDROID_STORE_URL;
      }
    };

    window.location.replace(getStoreUrl());
  }, []);

  return <DebouncedLineLoader />;
};

export default AppStoresRedirect;
