import { Typography } from '@styles/shared';
import React, { FC } from 'react';
import * as Styled from './FlatNumber.styles';
import { HousingOccupant } from '@modules/housing-occupants/model';

interface FlatNumberProps {
  occupant: HousingOccupant;
  className?: string;
}

const FlatNumber: FC<FlatNumberProps> = ({ occupant, className }) => {
  return (
    <Styled.FlatNumberContainer $unavailable={!occupant.isAvailable} className={className}>
      <Typography
        as="span"
        $level={!occupant.isAvailable ? 'small' : 'name'}
        aria-label={!occupant.isAvailable ? 'Indisponible' : `Numéro d'appartement ${occupant.number}`}
        $inverted
      >
        {!occupant.isAvailable ? 'Indisponible' : occupant.number}
      </Typography>
    </Styled.FlatNumberContainer>
  );
};

export default FlatNumber;
