import React, { FC } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import HousingOccupantListPage from '@modules/housing-occupants/ListPage';
import DoorContextProvider from '@modules/doors/context';
import Layout from '@layout/Layout';
import CodePage from '@modules/code/page/CodePage';
import CodeSuccess from '@modules/code/success/CodeSuccess';
import CodeError from '@modules/code/error/CodeError';
import ErrorPage from '@shared/components/error-page/ErrorPage';
import { HttpError } from '@core/http';

import { safeLazy } from '@core/router';
import AppStoresRedirect from '@modules/stores/AppStoresRedirect';

const CallPage = safeLazy(() => import('@modules/call/CallPage'));

const QRRoutesRedirection = () => {
  const { pathname } = useLocation();

  return <Navigate to={pathname.replace('/p', '')} replace />;
};

const RootRoutes: FC = () => {
  return (
    <Routes>
      <Route path="p/:number/*" element={<QRRoutesRedirection />} />

      <Route path=":number" element={<DoorContextProvider />}>
        <Route element={<Layout />}>
          <Route index element={<HousingOccupantListPage />} />
          <Route path="code" element={<CodePage />} />
        </Route>

        <Route element={<Layout footer={false} />}>
          <Route path="code/success" element={<CodeSuccess />} />
          <Route path="code/error" element={<CodeError />} />
        </Route>

        <Route path="call" element={<CallPage />} />
      </Route>

      <Route path="login" element={<AppStoresRedirect />} />

      <Route path="*" element={<ErrorPage error={HttpError.notFound} />} />
    </Routes>
  );
};

export default RootRoutes;
