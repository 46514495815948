import React, { FC, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import Header from './header/Header';
import * as Styled from './Layout.styles';
import { DebouncedLineLoader } from './loaders/line-loader/LineLoader';
import Footer from './footer/Footer';
import { useDoorContext } from '@modules/doors/context';

export const LAYOUT_CONTENT_ID = 'main';

interface LayoutProps {
  footer?: boolean;
}

const Layout: FC<LayoutProps> = ({ footer = true }) => {
  const { door } = useDoorContext();

  return (
    <>
      <Header door={door} />

      <Styled.LayoutContainer>
        <Styled.LayoutContent id={LAYOUT_CONTENT_ID} $footer={footer}>
          <Suspense fallback={<DebouncedLineLoader />}>
            <Outlet />
          </Suspense>
        </Styled.LayoutContent>
      </Styled.LayoutContainer>

      {footer ? <Footer /> : null}
    </>
  );
};

export default Layout;
