import { backgroundColor, headerBackground, readableColor, secondaryColor } from '@styles/utils';
import styled from 'styled-components';

export const HeaderContainer = styled.header`
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: ${headerBackground};
  padding: 10px 20px 10px 20px;
  width: 100%;
`;

export const HeaderImage = styled.div`
  margin-bottom: 15px;
`;

export const HeaderInfos = styled.div`
  display: grid;
  row-gap: 5px;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: ${backgroundColor('100')};
  border-radius: 10px;
  border: solid 1px rgba(112, 112, 112, 0.15);
  padding: 5px 20px;
  width: 100%;

  p {
    margin: 0;
  }
`;

export const HeaderInfosBuilding = styled.p`
  color: ${readableColor(secondaryColor)};

  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
`;
